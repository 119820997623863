import { Leads, LeadsHistory } from "@libs/types/Leads";
import { PaginationOptsExtended } from "@libs/types/Paginated";

type RequestOpts = {
  keyword?: string;
  status?: "VERIFIED" | "UNVERIFIED";
  partnerId?: number;
};

export type LeadsRequestOpts = RequestOpts & PaginationOptsExtended;

export interface LeadsReducerType {
  leads: Leads[];
  currentLeadsHistory: LeadsHistory[];
  opts?: LeadsRequestOpts;
  actionTarget?: Leads;
  loading?: boolean;
  sending?: boolean;
  cleanupToolbars?: boolean;
}

export enum LeadsActionTypes {
  GET_LEADS = "GET_LEADS",
  SET_LEAD_TARGET = "SET_LEAD_TARGET",
  UPDATE_LEAD_STATUS = "UPDATE_LEAD_STATUS",
  UPDATE_LEADS = "UPDATE_LEADS",
  GET_LEADS_HISTORY = "GET_LEADS_HISTORY",
}

export type GetLeadsEvent = {
  type: LeadsActionTypes.GET_LEADS;
  payload: {
    opts?: LeadsRequestOpts;
  };
};

export type GetLeadsEventSuccess = {
  type: `${LeadsActionTypes.GET_LEADS}_SUCCESS`;
  payload: Leads[];
};

export type GetLeadsEventFail = {
  type: `${LeadsActionTypes.GET_LEADS}_FAIL`;
  payload?: {
    errorMessage?: string;
  };
};

export type SetLeadTargetEvent = {
  type: LeadsActionTypes.SET_LEAD_TARGET;
  payload?: Leads;
};

export type UpdateLeadsEvent = {
  type: LeadsActionTypes.UPDATE_LEADS;
  payload: Leads;
};

export type UpdateLeadsSuccess = {
  type: `${LeadsActionTypes.UPDATE_LEADS}_SUCCESS`;
  payload: Leads;
};

export type UpdateLeadsFail = {
  type: `${LeadsActionTypes.UPDATE_LEADS}_FAIL`;
  payload?: {
    errorMessage?: string;
  };
};

//

export type GetLeadsHistoryEvent = {
  type: LeadsActionTypes.GET_LEADS_HISTORY;
  payload: number;
};

export type GetLeadsHistorySuccess = {
  type: `${LeadsActionTypes.GET_LEADS_HISTORY}_SUCCESS`;
  payload: LeadsHistory[];
};

export type GetLeadsHistoryFail = {
  type: `${LeadsActionTypes.GET_LEADS_HISTORY}_FAIL`;
  payload?: {
    errorMessage?: string;
  };
};

export type LeadsActions =
  | GetLeadsEvent
  | GetLeadsEventSuccess
  | GetLeadsEventFail
  | SetLeadTargetEvent
  | UpdateLeadsEvent
  | UpdateLeadsSuccess
  | UpdateLeadsFail
  | GetLeadsHistoryEvent
  | GetLeadsEventSuccess
  | GetLeadsEventFail;
