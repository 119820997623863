import { getIdToken } from "@libs/networking";
import { Leads, LeadsHistory } from "@libs/types/Leads";
import { Paginated } from "@libs/types/Paginated";
import { getFinxApi } from "@utilities/management-api";

const finxApi = getFinxApi();

export async function getAllLeadProducts() {
  return await finxApi.get(`leads-product?per_page=9999`, {
    Authorization: getIdToken(),
  });
}

export async function getAllLeads(): Promise<Paginated<Leads>> {
  return await finxApi.get(`leads?per_page=9999`, {
    Authorization: getIdToken(),
  });
}

export async function getLeadsHistory(leadsId: number): Promise<LeadsHistory> {
  return await finxApi.get(`leads/${leadsId}/history`, {
    Authorization: getIdToken(),
  });
}

export async function updateLeads(id: number, leads: Leads): Promise<Leads> {
  const resp = await finxApi.put(`leads/${id}`, leads, {
    Authorization: getIdToken(),
  });

  return resp.data;
}
