/* eslint-disable @typescript-eslint/ban-ts-comment */
import { all, call, put, takeLatest } from "redux-saga/effects";
import {
  getAllLeads,
  updateLeads,
  getLeadsHistory,
} from "@services/leads.service";
import {
  getLeadsFail,
  getLeadsSuccess,
  updateLeadsSuccess,
  updateLeadsFail,
  getLeadsHistorySuccess,
  getLeadsHistoryFail,
} from "@reduxsaga/reducers/leads/leads-action";
import {
  GetLeadsHistoryEvent,
  LeadsActionTypes,
  UpdateLeadsEvent,
} from "@reduxsaga/reducers/leads/leads-type";
import { Leads } from "@libs/types/Leads";
import { message } from "antd";

function* getLeadsSaga() {
  try {
    // @ts-ignore
    const resp = yield call(getAllLeads);
    yield put(getLeadsSuccess(resp.data));
  } catch (err) {
    console.error(err);
    yield put(getLeadsFail(err));
  }
}

function* getLeadsHistorySaga(params: GetLeadsHistoryEvent) {
  try {
    // @ts-ignore
    const resp = yield call(getLeadsHistory, params.payload);
    console.log({ resp });
    yield put(getLeadsHistorySuccess(resp));
  } catch (err) {
    // @ts-ignore
    const errMessage = `${err.toString()}`;
    yield put(getLeadsHistoryFail(errMessage));
  }
}

function* updateLeadsSaga(params: UpdateLeadsEvent) {
  try {
    const resp: Leads = yield call(
      updateLeads,
      params.payload.id,
      params.payload
    );
    yield put(updateLeadsSuccess(resp));
    message.success("success");
  } catch (err) {
    // @ts-ignore
    const errMessage = `${err.toString()}`;
    yield put(updateLeadsFail(errMessage));
    message.error(errMessage);
  }
}

function* leadsSaga() {
  yield all([
    takeLatest(LeadsActionTypes.GET_LEADS, getLeadsSaga),
    takeLatest(LeadsActionTypes.GET_LEADS_HISTORY, getLeadsHistorySaga),
    takeLatest(`${LeadsActionTypes.UPDATE_LEADS}_SUCCESS`, getLeadsSaga),
    takeLatest(LeadsActionTypes.UPDATE_LEADS, updateLeadsSaga),
  ]);
}

export default leadsSaga;
