import { Partner, User } from "./User";

export interface LeadsHistory {
  id: number;
  leadsId: number;
  status: string;
  historyType: string;
  userId?: any;
  comments?: any;
  createdAt: Date;
  updatedAt: Date;
  deletedAt?: any;
}

export interface LeadsProduct {
  name: string;
  description: string;
  partner: Partner;
  startAt?: Date;
  endAt?: Date;
  createdAt: Date;
  updatedAt: Date;
}

export interface Leads {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  user: User;
  partner: Partner;
  verifiedAt?: Date;
  createdAt: Date;
  updatedAt: Date;
  status?: string;
  additionalFormValues?: string;
  comments?: string;
  product?: {
    name: string;
  };
}

export interface LeadsFormOptionItem {
  label: string;
  value: string;
}
export interface LeadsFormItem {
  type: "text" | "text-area" | "select" | "number" | "text-price";
  label: string;
  name: string;
  value: any;
  required?: boolean;
  options?: LeadsFormOptionItem[];
}

export const leadsTypes: string[] = [
  "PROCESSING",
  "AWAITING_USER_AGREEMENT",
  "USER_AGREE",
  "ON_PARTNER_PROCESS",
  "FAILED",
  "REJECTED",
  "CANCELLED",
  "COMPLETED",
];
