import { Leads } from "@libs/types/Leads";
import { LeadsActions, LeadsReducerType, LeadsActionTypes } from "./leads-type";

const initialState: LeadsReducerType = {
  leads: [],
  currentLeadsHistory: [],
};

const reducers = (state = initialState, action: LeadsActions) => {
  switch (action.type) {
    case LeadsActionTypes.GET_LEADS:
      return {
        ...state,
        opts: action.payload,
        loading: state.leads.length === 0,
      };
    case `${LeadsActionTypes.GET_LEADS}_SUCCESS`:
      return {
        ...state,
        leads: action.payload,
        loading: false,
      };
    case `${LeadsActionTypes.GET_LEADS}_FAIL`:
      return {
        ...state,
        loading: false,
      };
    case LeadsActionTypes.SET_LEAD_TARGET: {
      return {
        ...state,
        actionTarget: action.payload,
      };
    }
    case LeadsActionTypes.UPDATE_LEADS: {
      return {
        ...state,
        sending: true,
      };
    }
    case `${LeadsActionTypes.UPDATE_LEADS}_SUCCESS`: {
      const updateIndex = state.leads.findIndex(
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        (l: Leads) => l.id === action?.payload?.id
      );
      const leads: Leads[] = [...state.leads];
      if (leads && leads[updateIndex] && action.payload)
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        leads[updateIndex] = action.payload;

      return {
        ...state,
        sending: false,
        actionTarget: undefined,
        leads,
      };
    }
    case `${LeadsActionTypes.UPDATE_LEADS}_FAIL`: {
      return {
        ...state,
        sending: false,
      };
    }
    case LeadsActionTypes.GET_LEADS_HISTORY:
    case `${LeadsActionTypes.GET_LEADS_HISTORY}_FAIL`:
      return {
        ...state,
        currentLeadsHistory: [],
      };
    case `${LeadsActionTypes.GET_LEADS_HISTORY}_SUCCESS`:
      return {
        ...state,
        currentLeadsHistory: action.payload,
      };
    default:
      return {
        ...state,
      };
  }
};

export default reducers;
